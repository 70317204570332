import * as React from "react";
import { BiblioTechContents, Container, Breadcrumb } from "../../components";
import Seo from "../../components/Seo";

const BiblioTechPage = () => {
  return (
    <Container>
      <Seo
        title="BiblioTech プログラミングスクール"
        description="BiblioTechは未経験から市場価値の高いエンジニアを目指すオンラインスクールです。私達と二人三脚でプログラミングを学ぶことであなたのキャリアアップ実現をお約束します。"
      />
      <BiblioTechContents />
      <Breadcrumb
        currentPage="BiblioTech"
        currentSlug="/services/bibliotech"
        parentPage="Services"
        parentSlug="/services"
      />
    </Container>
  );
};

export default BiblioTechPage;
